var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "example" }, [
    _c(
      "div",
      { staticClass: "example__line" },
      [
        _vm.node.formatType === "circle"
          ? [
              _c("FlowCircle", {
                attrs: {
                  item: _vm.node,
                  icon: _vm.node.icon,
                  color: _vm.node.color,
                  isReportRender: _vm.isReportRender,
                  shouldValidate: _vm.node.shouldValidate,
                  hasNextNode: _vm.node.hasNextNode,
                },
                on: {
                  linkClick: function ($event) {
                    return _vm.clickLinkProp(_vm.node.id)
                  },
                  node_click: function ($event) {
                    return _vm.$emit("node_click", $event)
                  },
                  node_click_right: function ($event) {
                    return _vm.$emit("node_click_right", $event)
                  },
                  rename_node: function ($event) {
                    return _vm.$emit("rename_node", $event)
                  },
                  joinInformations: function ($event) {
                    return _vm.$emit("joinInformations", $event)
                  },
                },
              }),
            ]
          : _vm._e(),
        _vm.node.formatType === "square"
          ? [
              _c("FlowSquare", {
                attrs: {
                  item: _vm.node,
                  icon: _vm.node.icon,
                  color: _vm.node.color,
                  isReportRender: _vm.isReportRender,
                  shouldValidate: _vm.node.shouldValidate,
                  hasNextNode: _vm.node.hasNextNode,
                },
                on: {
                  linkClick: function ($event) {
                    return _vm.clickLinkProp(_vm.node.id)
                  },
                  node_click: function ($event) {
                    return _vm.$emit("node_click", $event)
                  },
                  node_click_right: function ($event) {
                    return _vm.$emit("node_click_right", $event)
                  },
                  rename_node: function ($event) {
                    return _vm.$emit("rename_node", $event)
                  },
                },
              }),
            ]
          : _vm._e(),
        _vm.node.formatType === "diamond"
          ? [
              _c(
                "div",
                { staticClass: "diamond" },
                [
                  _c("FlowDiamond", {
                    attrs: {
                      item: _vm.node,
                      color: _vm.node.color,
                      hasNextNode: _vm.node.hasNextNode,
                      isReportRender: _vm.isReportRender,
                      shouldValidate: _vm.node.shouldValidate,
                    },
                    on: {
                      node_click: function ($event) {
                        return _vm.$emit("node_click", $event)
                      },
                      yes_click: _vm.yesClickProp,
                      no_click: _vm.noClickProp,
                      node_click_right: function ($event) {
                        return _vm.$emit("node_click_right", $event)
                      },
                      rename_node: function ($event) {
                        return _vm.$emit("rename_node", $event)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _vm.node.YesNext != null
                                ? _c(
                                    "div",
                                    { staticClass: "diamond__childs" },
                                    [
                                      _c("TElement", {
                                        attrs: {
                                          "click-link-prop": _vm.clickLinkProp,
                                          node: _vm.node.YesNext,
                                          "yes-click-prop": _vm.yesClickProp,
                                          "no-click-prop": _vm.noClickProp,
                                        },
                                        on: {
                                          node_click: function ($event) {
                                            return _vm.$emit(
                                              "node_click",
                                              $event
                                            )
                                          },
                                          node_click_right: function ($event) {
                                            return _vm.$emit(
                                              "node_click_right",
                                              $event
                                            )
                                          },
                                          rename_node: function ($event) {
                                            return _vm.$emit(
                                              "rename_node",
                                              $event
                                            )
                                          },
                                          joinInformations: function ($event) {
                                            return _vm.$emit(
                                              "joinInformations",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      250904638
                    ),
                  }),
                ],
                1
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
    _vm.hasChildren && _vm.node.nextNode != null
      ? _c(
          "div",
          { staticClass: "example__next" },
          [
            _c("TElement", {
              attrs: {
                node: _vm.node.nextNode,
                "click-link-prop": _vm.clickLinkProp,
                "no-click-prop": _vm.noClickProp,
                isReportRender: _vm.isReportRender,
                "yes-click-prop": _vm.yesClickProp,
              },
              on: {
                node_click: function ($event) {
                  return _vm.$emit("node_click", $event)
                },
                node_click_right: function ($event) {
                  return _vm.$emit("node_click_right", $event)
                },
                rename_node: function ($event) {
                  return _vm.$emit("rename_node", $event)
                },
                joinInformations: function ($event) {
                  return _vm.$emit("joinInformations", $event)
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.hasYesChildren &&
    _vm.node.fromClick.toLowerCase() == "yes" &&
    _vm.node.nextNode == null
      ? _c(
          "div",
          { staticClass: "example__next" },
          [
            _vm.node.YesNext != null
              ? _c("TElement", {
                  attrs: {
                    node: _vm.node.YesNext,
                    "click-link-prop": _vm.clickLinkProp,
                    isReportRender: _vm.isReportRender,
                    "no-click-prop": _vm.noClickProp,
                    "yes-click-prop": _vm.yesClickProp,
                  },
                  on: {
                    node_click: function ($event) {
                      return _vm.$emit("node_click", $event)
                    },
                    node_click_right: function ($event) {
                      return _vm.$emit("node_click_right", $event)
                    },
                    joinInformations: function ($event) {
                      return _vm.$emit("joinInformations", $event)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }