<template>
  <div class="square">
    <div
      v-if="(isActive || isHoverOnProperElement) && !isReportRender"
      class="square__remove"
      @mouseover.self="handleMouseOverOnProperElement"
      @mouseleave.self="handleMouseLeaveOnProperElement"
    >
      <v-icon small @click="$refs.contextMenu.open($event, 'data')"> mdi-pencil </v-icon>
    </div>
    <!-- @click.right.prevent="$refs.contextMenu.open($event, 'data')" -->
    <!-- border: currentClickedNodeId === item.id &&  !shouldValidate? '3.5px dashed #E46651 !important' : '' -->
    <div
      class="square__content"
      :style="{
        'background-color': getColor,
        border: shouldValidate && !currentClickedNodeId ? '1px solid red !important' : '',
        border:
          clickedNodesList &&
          clickedNodesList.find((el) => el.id == item.id) &&
          !shouldValidate
            ? '3.5px dashed #E46651 !important'
            : '',
      }"
      @click="$emit('node_click', item.id)"
      @mouseover.self="handleMouseOver"
      @mouseenter.self="handleMouseEnter"
      @mouseleave.self="handleMouseLeave"
    >
      <z-context-menu
        ref="contextMenu"
        :options="optionsMenu"
        @click="onClickContextAction"
      />
      <div class="square__content__icon">
        <v-icon color="white"> {{ icon }}</v-icon>
      </div>
      <div class="square__content__text">
        <span>{{ item.label }}</span>
        <!-- <span
          >{{ item.label }} {{ currentNodeHaveMerge }}
          <small
            ><strong style="color: red"> {{ leftLineSize2.width }}</strong></small
          ></span
        > -->
      </div>
    </div>
    <div
      v-if="hasNextNode"
      @mouseover="showAddButton()"
      @mouseleave="hideAddButton()"
      :class="{ square__line: !currentNodeHaveMerge }"
      :style="[
        currentNodeHaveMerge ? leftLineSize : currentNodeMakesMerge ? leftLineSize2 : '',
      ]"
      style="margin-right: 10px"
    >
      <figure
        v-if="!isReportRender && !currentNodeHaveMerge"
        @click="linkClick"
        :id="item.id"
        style="
          display: none;
          position: relative;
          top: -11px;
          left: 3px;
          z-index: 5 !important;
        "
      >
        <img
          style="margin-left: 15px; position: relative"
          src="/icons/add-circle-rounded-icon.svg"
        />
      </figure>
    </div>

    <!-- <div class="square__line-plus">
      <div class="line"></div>
      <figure>
        <img src="/icons/add-square-rounded-icon.svg" />
      </figure>
      <div class="line"></div>
    </div> -->
  </div>
</template>

<script>
import shapesMixin from "./shapesMixin";
export default {
  name: "FlowSquare",
  mixins: [shapesMixin],
  inject: ["currentClickedNodeId", "clickedNodesList"],
  props: {
    item: { type: Object, default: null, required: true },
    icon: { type: String, default: "" },
    color: { type: String, default: "#0d99ff" },
    hasNextNode: { type: Boolean, default: false },
    shouldValidate: { type: Boolean, default: false },
    isReportRender: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      optionsMenu: [
        { action: "delete", text: "Remover", value: 0 },
        { action: "rename", text: "Renomear", value: 1 },
      ],
    };
  },
  computed: {
    leftLineSize() {
      const style = {
        position: "absolute",
        bottom: "30px",
        left: "70px",
        borderBottom: "3px solid #b3b3b3",
        borderRight: "3px solid #b3b3b3",
        background: "transparent",
        height: "30px",
        width: "60px",
        borderRadius: "0 0 20px 0 ",
        cursor: "default",
      };

      // informações montadas no mixin
      if (this.nodeIndexToJoin > this.selfIndexToJoin) {
        // let controller = this.selfIndexToJoin;
        let t = this.nodeIndexToJoin - this.selfIndexToJoin;
        let newWidth = 100 * t;
        //   console.log(t, this.item.label, this.nodeIndexToJoin, this.selfIndexToJoin);

        // adicionar mais uma pequena porcentagem a partir do tamanho do newWidth atual ?
        if (t > 1 && t < 3) {
          //    console.log("a");
          let percent = (newWidth * 20.5) / 100;
          newWidth += percent;
        }

        if (t >= 3) {
          //     console.log("b");
          let percent = (newWidth * 25) / 100;
          newWidth += percent;
        }
        style.width = `${newWidth}px !important`;
      }

      return style;
    },
    leftLineSize2() {
      const style = {
        border: "1px solid #b3b3b3;",
        background: "#b3b3b3;",
        height: " 3px;",
        width: "60px;",
        borderRadius: "20px;",
        cursor: "pointer;",
      };

      // informações montadas no mixin
      if (this.selfIndexMakesMerge < this.nodeIndexMakeMergeTojoin) {
        // let controller = this.selfIndexToJoin;
        //   let t = this.nodeIndexMakeMergeTojoin - this.selfIndexMakesMerge;
        let newWidth = 100 * this.nodeIndexMakeMergeTojoin;
        // console.log(t, 't here')

        // adicionar mais uma pequena porcentagem a partir do tamanho do newWidth atual ?
        // if (t > 1 && t < 3) {
        //   let percent = (newWidth * 20.5) / 100;
        //   newWidth += percent;
        // }

        if (this.nodeIndexMakeMergeTojoin > 3) {
          let percent = (newWidth * 10) / 100;
          newWidth += percent;
        }

        if (this.nodeIndexMakeMergeTojoin >= 5) {
          let percent = (newWidth * 5) / 100;
          newWidth += percent;
        }
        style.width = `${newWidth}px`;
      }

      return style;
    },
    getColor() {
      // const SELECT_COLOR = !this.item.isDataSelected ? "#DCDCDC" : this.color;
      let SELECT_COLOR =
        this.item.isDataSelected && this.item.templateId ? this.color : "#DCDCDC";

      // caso o chip seja do facebook, deve sobrecrever lógica com a cor do chip
      this.item.type.includes("sendFacebook") &&
      this.item.isDataSelected &&
      this.item.collectionId
        ? (SELECT_COLOR = this.item.color)
        : "";

      return `${SELECT_COLOR} !important`;
    },
  },
  methods: {
    onClickContextAction({ option }) {
      switch (option.value) {
        case 0:
          this.deleteContent();
          break;
        case 1:
          this.renameContent();
          break;
      }
    },
    deleteContent() {
      this.$emit("node_click_right", this.item.id);
    },
    renameContent() {
      if (this.item.label.toLowerCase() != "final") this.$emit("rename_node", this.item);
    },
    linkClick() {
      this.$emit("linkClick", this.item.id);
    },

    // showAddButton() {
    //   let addButton = document.getElementById(this.item.id);
    //   addButton.style.display = "block";
    // },
    // hideAddButton() {
    //   let addButton = document.getElementById(this.item.id);
    //   addButton.style.display = "none";
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.square {
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  &__remove {
    position: absolute;
    top: -28.5px;
    left: 23.5px;
  }
  &__content {
    background: #0d99ff !important;
    padding: 0.5rem;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    & > div {
      flex: 1 0 100%;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__text {
      position: relative;
      top: 8px;
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      line-height: 15px;
      text-align: center;
      span {
        font-size: 0.75rem;
        letter-spacing: -0.011em;
      }
    }
  }

  &__line {
    border: 1px solid #b3b3b3;
    background: #b3b3b3;
    height: 3px;
    width: 60px;
    border-radius: 20px;
  }
  &__line-plus {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    figure {
      width: 20px;
      height: 20px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .line {
      background: #b3b3b3;
      height: 3px;
      width: 20px;
      border-radius: 20px;
    }
  }
}
</style>
