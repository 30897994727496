<template>
  <div class="example">
    <!-- NESSE COMPONENTE AS INFORMAÇÕES ATUALIZAM, CORRETAMENTE, O PROBLEMA ESTÁ NO NÓ -->
    <TreeItem
      :node="info"
      :key="key"
      :isReportRender="isReportRender"
      :click-link-prop="clickedChild"
      :no-click-prop="no_click"
      :yes-click-prop="yes_click"
      @node_click="$emit('node_click', $event)"
      @node_click_right="$emit('node_click_right', $event)"
      @rename_node="$emit('rename_node', $event)"
      @joinInformations="$emit('joinInformations', $event)"
    />
  </div>
</template>

<script>
import { computed } from "vue";
import TreeItem from "./ExampleItem.vue";
export default {
  props: {
    nodes: { type: Object, required: true, default: null },
    isReportRender: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      key: 0,
      info: this.nodes,
    };
  },
  provide(){
return{
  completeTreeProp: computed(() => this.info),
}
  },  
  watch: {
    nodes: {
      handler() {
        this.info = this.nodes;
        this.key++;
      },
      deep: true,
    },
  },
  components: {
    TreeItem,
  },
  methods: {
    yes_click(event) {
      this.$emit("yes_click", event);
    },

    no_click(event) {
      this.$emit("no_click", event);
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    clickedChild(e) {
      this.$emit("linkClick", e);
    },
  },
};
</script>

<style lang="scss" scoped></style>
