var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "line-content",
      staticStyle: { "margin-right": "10px" },
      on: {
        mouseover: function ($event) {
          return _vm.showAddButtonNAO()
        },
        mouseleave: function ($event) {
          return _vm.hideAddButtonNAO()
        },
      },
    },
    [
      _c(
        "span",
        {
          staticStyle: { display: "none" },
          attrs: { id: _vm.item.id + "_nao_span" },
        },
        [_vm._v(_vm._s(_vm.title) + " ")]
      ),
      !_vm.isReportRender
        ? _c(
            "figure",
            {
              staticStyle: {
                display: "block",
                position: "relative",
                top: "3px",
                left: "-8px",
                "z-index": "5 !important",
                bottom: "-17px",
              },
              attrs: { id: _vm.item.id + "_nao" },
              on: {
                click: function ($event) {
                  return _vm.$emit("click")
                },
              },
            },
            [
              _c("img", {
                staticStyle: { "margin-left": "15px", position: "relative" },
                attrs: { src: "/icons/add-circle-rounded-icon.svg" },
              }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }