var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "square" }, [
    _vm.isActive || _vm.isHoverOnProperElement
      ? _c(
          "div",
          {
            staticClass: "square__remove",
            on: {
              mouseover: function ($event) {
                if ($event.target !== $event.currentTarget) return null
                return _vm.handleMouseOverOnProperElement.apply(null, arguments)
              },
              mouseleave: function ($event) {
                if ($event.target !== $event.currentTarget) return null
                return _vm.handleMouseLeaveOnProperElement.apply(
                  null,
                  arguments
                )
              },
            },
          },
          [
            _c(
              "v-icon",
              {
                attrs: { small: "" },
                on: {
                  click: function ($event) {
                    if ($event.target !== $event.currentTarget) return null
                    return _vm.$refs.contextMenu.open($event, "data")
                  },
                },
              },
              [_vm._v(" mdi-pencil ")]
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "square__content",
        style: {
          "background-color": _vm.getColor,
          border: _vm.shouldValidate ? "1px solid red !important" : "",
          border:
            _vm.clickedNodesList.length &&
            _vm.clickedNodesList.find((el) => el.id == _vm.item.id) &&
            !_vm.shouldValidate
              ? "3px dashed #E46651 !important"
              : "",
        },
        on: {
          click: function ($event) {
            return _vm.$emit("node_click", _vm.item.id)
          },
          mouseover: function ($event) {
            if ($event.target !== $event.currentTarget) return null
            return _vm.handleMouseOver.apply(null, arguments)
          },
          mouseenter: function ($event) {
            if ($event.target !== $event.currentTarget) return null
            return _vm.handleMouseEnter.apply(null, arguments)
          },
          mouseleave: function ($event) {
            if ($event.target !== $event.currentTarget) return null
            return _vm.handleMouseLeave.apply(null, arguments)
          },
        },
      },
      [
        _c("z-context-menu", {
          ref: "contextMenu",
          attrs: { options: _vm.optionsMenu },
          on: { click: _vm.onClickContextAction },
        }),
        _c("div", { staticClass: "square__content--not-rotate" }, [
          _c(
            "div",
            {
              staticClass: "square__content--not-rotate__icon",
              style: _vm.item.type.includes("mensageriaCheck")
                ? "padding-top: 35px !important;"
                : "",
            },
            [
              _vm.item.type.includes("selectTestABFilter")
                ? _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v(" $call_split_rounded"),
                  ])
                : _vm.item.type.includes("mensageriaCheck")
                ? _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v("mdi-email"),
                  ])
                : _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v(" mdi-contacts"),
                  ]),
            ],
            1
          ),
          _c("div", { staticClass: "square__content--not-rotate__text" }, [
            _vm._v(" " + _vm._s(_vm.item.label) + " "),
          ]),
        ]),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("ExampleItemLine", {
          attrs: {
            title:
              _vm.item.type !== "selectTestABFilter"
                ? "Não"
                : _vm.item.percentageB
                ? _vm.item.percentageB.toString() + "%"
                : "0%",
            isReportRender: _vm.isReportRender,
            item: _vm.item,
          },
          on: {
            click: function ($event) {
              return _vm.$emit("no_click", _vm.item.id)
            },
          },
        }),
        _c(
          "div",
          {
            staticClass: "square__line yes-line",
            style: {
              height: `${_vm.heightYesLine}px `,
              bottom: `-${_vm.bottomYesLine}px`,
              width: "150px",
              marginLeft: "12px",
            },
            on: {
              mouseover: function ($event) {
                return _vm.showAddButtonSIM()
              },
              mouseleave: function ($event) {
                return _vm.hideAddButtonSIM()
              },
            },
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  background: "#f4f4f4",
                  color: "black",
                  "font-weight": "500",
                  padding: "3px",
                },
                attrs: { id: _vm.item.id + "_sim_span" },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.item.type !== "selectTestABFilter"
                        ? "Sim"
                        : _vm.item.percentageA
                        ? _vm.item.percentageA.toString() + "%"
                        : "0%"
                    ) +
                    " "
                ),
              ]
            ),
            !_vm.isReportRender
              ? _c(
                  "figure",
                  {
                    style: {
                      bottom: `-17px !important`,
                      display: "none",
                      position: "relative",
                      top: `${_vm.topYesLineButton}px !important`,
                      left: "-7px",
                      zIndex: "5 !important",
                    },
                    attrs: { id: _vm.item.id + "_sim" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("yes_click", _vm.item.id)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticStyle: {
                        "margin-left": "15px",
                        position: "relative",
                      },
                      attrs: { src: "/icons/add-circle-rounded-icon.svg" },
                    }),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "yes-line__content" },
              [_vm._t("content")],
              2
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }