<template>
  <div
    class="line-content"
    @mouseover="showAddButtonNAO()"
    @mouseleave="hideAddButtonNAO()"
    style="margin-right: 10px"
  >
    <span :id="item.id + '_nao_span'" style="display: none">{{ title }} </span>
    <figure
      v-if="!isReportRender"
      @click="$emit('click')"
      :id="item.id + '_nao'"
      style="
        display: block;
        position: relative;
        top: 3px;
        left: -8px;
        z-index: 5 !important;
        bottom: -17px;
      "
    >
      <img
        style="margin-left: 15px; position: relative"
        src="/icons/add-circle-rounded-icon.svg"
      />
    </figure>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "Título" },
    item: { type: Object, default: null, required: true },
    isReportRender: { type: Boolean, required: false, default: false },
  },
  methods: {
    showAddButtonNAO() {
      let addButton = document.getElementById(this.item.id + "_nao");
      let simSpan = document.getElementById(this.item.id + "_nao_span");
      addButton.style.display = "block";
      simSpan.style.display = "none";
    },
    hideAddButtonNAO() {
      let addButton = document.getElementById(this.item.id + "_nao");
      let simSpan = document.getElementById(this.item.id + "_nao_span");
      simSpan.style.display = "block";
      addButton.style.display = "none";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.line-content {
  cursor: pointer;
  width: 90px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b3b3b3;
  background: #b3b3b3;
  height: 3px;
  border-radius: 20px;

  span {
    background: #f4f4f4;
    color: black;
    font-weight: 500;
    padding: 3px;
  }
}
</style>
