<template>
  <div class="example">
    <div class="example__line">
      <!-- VERIFICA QUAL TIPO DE ELEMENTO, PARA MONTAR DA FORMA ADEQUADA -->
      <template v-if="node.formatType === 'circle'">
        <FlowCircle
          
          :item="node"
          :icon="node.icon"
          :color="node.color"
          :isReportRender="isReportRender"
          :shouldValidate="node.shouldValidate"
          :hasNextNode="node.hasNextNode"
          @linkClick="clickLinkProp(node.id)"
          @node_click="$emit('node_click', $event)"
          @node_click_right="$emit('node_click_right', $event)"
          @rename_node="$emit('rename_node', $event)"
          @joinInformations="$emit('joinInformations', $event)"
        />
      </template>

      <template v-if="node.formatType === 'square'">
        <FlowSquare
        
          :item="node"
          :icon="node.icon"
          :color="node.color"
          :isReportRender="isReportRender"
          :shouldValidate="node.shouldValidate"
          :hasNextNode="node.hasNextNode"
          @linkClick="clickLinkProp(node.id)"
          @node_click="$emit('node_click', $event)"
          @node_click_right="$emit('node_click_right', $event)"
          @rename_node="$emit('rename_node', $event)"
        />
      </template>

      <template v-if="node.formatType === 'diamond'">
        <div class="diamond">
          <FlowDiamond
          
            :item="node"
            :color="node.color"
            :hasNextNode="node.hasNextNode"
            :isReportRender="isReportRender"
            :shouldValidate="node.shouldValidate"
            @node_click="$emit('node_click', $event)"
            @yes_click="yesClickProp"
            @no_click="noClickProp"
            @node_click_right="$emit('node_click_right', $event)"
            @rename_node="$emit('rename_node', $event)"
          >
            <!-- LINHA CONTENDO OS ELEMENTOS DA LINHA 'SIM' -->
            <template #content>
              <div v-if="node.YesNext != null" class="diamond__childs">
                <TElement
                
                  :click-link-prop="clickLinkProp"
                  :node="node.YesNext"
                  :yes-click-prop="yesClickProp"
                  :no-click-prop="noClickProp"
                  @node_click="$emit('node_click', $event)"
                  @node_click_right="$emit('node_click_right', $event)"
                  @rename_node="$emit('rename_node', $event)"
                  @joinInformations="$emit('joinInformations', $event)"
                />
              </div>
            </template>
          </FlowDiamond>
        </div>
      </template>
    </div>

    <div v-if="hasChildren && node.nextNode != null" class="example__next">
      <TElement
      
        :node="node.nextNode"
        :click-link-prop="clickLinkProp"
        :no-click-prop="noClickProp"
        :isReportRender="isReportRender"
        :yes-click-prop="yesClickProp"
        @node_click="$emit('node_click', $event)"
        @node_click_right="$emit('node_click_right', $event)"
        @rename_node="$emit('rename_node', $event)"
        @joinInformations="$emit('joinInformations', $event)"
      />
    </div>

    <!-- SE NECESSÁRIO, REMOVER O NODE.NEXTNODE == NULL, MAS ANTES, A ARVORE PRECISA SER MONTADA CORRETAMENTE, PARA TER CERTEZA DA UTILIZAÇÃO DESSA VERIFICAÇÃO -->
    <div
      v-if="
        hasYesChildren && node.fromClick.toLowerCase() == 'yes' && node.nextNode == null
      "
      class="example__next"
    >
      <TElement
        v-if="node.YesNext != null"
        
        :node="node.YesNext"
        :click-link-prop="clickLinkProp"
        :isReportRender="isReportRender"
        :no-click-prop="noClickProp"
        :yes-click-prop="yesClickProp"
        @node_click="$emit('node_click', $event)"
        @node_click_right="$emit('node_click_right', $event)"
        @joinInformations="$emit('joinInformations', $event)"
      />
    </div>
  </div>
</template>

<script>
import FlowCircle from "./Circle2.vue";
import FlowSquare from "./Square.vue";
import FlowDiamond from "./Diamond3.vue";
export default {
  name: "TElement",
  props: {
    node: { type: Object, required: true },
    clickLinkProp: { type: Function, default: null },
    noClickProp: { type: Function, default: null },
    yesClickProp: { type: Function, default: null },
    isReportRender: { type: Boolean, required: false, default: false },
  },
  data() {
    return {};
  },
  watch: {
    node: {
      handler() {},
      deep: true,
    },
  },
  components: {
    FlowCircle,
    FlowSquare,
    FlowDiamond,
  },
  computed: {
    hasChildren() {
      const { nextNode } = this.node;
      if (nextNode == null) return false;
      return !this.isEmpty(nextNode);
    },
    hasYesChildren() {
      const { YesNext } = this.node;
      if (YesNext == null || this.node.nextJoinNode != null) return false;
      return !this.isEmpty(YesNext);
    },
  },
  methods: {
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.example {
  display: flex;
  align-items: center;
 
}
</style>
