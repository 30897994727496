var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "example" },
    [
      _c("TreeItem", {
        key: _vm.key,
        attrs: {
          node: _vm.info,
          isReportRender: _vm.isReportRender,
          "click-link-prop": _vm.clickedChild,
          "no-click-prop": _vm.no_click,
          "yes-click-prop": _vm.yes_click,
        },
        on: {
          node_click: function ($event) {
            return _vm.$emit("node_click", $event)
          },
          node_click_right: function ($event) {
            return _vm.$emit("node_click_right", $event)
          },
          rename_node: function ($event) {
            return _vm.$emit("rename_node", $event)
          },
          joinInformations: function ($event) {
            return _vm.$emit("joinInformations", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }